<template>
  <b-card
    v-if="data"
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <h6 class="mb-0 text-uppercase">
            {{ new Date() | moment("ddd") }}
          </h6>
          <h3 class="mb-0">
            {{ new Date() | moment("DD") }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            Team Member Time Off
          </b-card-title>
          <b-card-text class="mb-0">
            Who's on PTO?
          </b-card-text>
          <b-progress
            v-if="data.queryCount === 0"
            :value="barValue"
            max="100"
          />
        </div>
      </div>
      <!--/ metting header -->

      <!-- media -->
      <span v-if="data.queryCount != 0">
        <b-media
          v-for="media in data.results[0].Group"
          :key="media.fullname"
          no-body
        >
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ media.fullname }}
            </h6>
            <small>{{ media.type }} | {{ media.status }} | {{ media.hours }} hours</small>
          </b-media-body>
        </b-media>
      </span>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BProgress,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      barValue: 0,
      timer: 0,
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) { this.barValue = 0 }
      this.barValue += 5
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
}
</script>
