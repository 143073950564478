<template>
  <b-card
    v-if="data"
    class="text-center"
  >
    <h3><br><br>Welcome {{ data.name }}!</h3>
    <div v-if="ticketCount > 0">
      You have {{ ticketCount }} open tickets!
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import { integer } from 'vee-validate/dist/rules'

export default {
  components: {
    BCard,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    ticketCount: {
      type: integer,
      default: () => {},
    },
  },
}
</script>
