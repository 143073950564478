<template>
  <b-card
    v-if="data"
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Call Statistics</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        Updated {{ data.queryTime.value | moment('timezone', 'UTC', 'from') }}
        <b-progress
          v-if="data.queryCount === 0"
          class="mt-n3"
          :value="barValue"
          max="100"
        />
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">

      <b-row>
        <b-col
          xl="6"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="success"
              >
                <feather-icon
                  size="24"
                  icon="PhoneCallIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder font-large-1 mb-0">
                {{ data.callsTodaySls }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Sales
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col
          xl="6"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="success"
              >
                <feather-icon
                  size="24"
                  icon="PhoneCallIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder font-large-1 mb-0">
                {{ data.callsTodayCSTS }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                CS / TS
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BProgress,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BProgress,
  },
  props: {
    data: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      barValue: 0,
      timer: 0,
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) { this.barValue = 0 }
      this.barValue += 5
    }, 300)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
}
</script>
