<template>
  <section id="dashboard-home">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
        
      >
        <home-welcome
          class="pb-3"
          :data="$auth.user"
          :ticket-count="ticketCount"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- PTO Card -->
      <b-col
        lg="4"
        md="6"
      >
        <home-p-t-o :data="dataTimeOff" />
      </b-col>
      <!--/ PTO Card -->
      <!-- Queue Stats Card -->
      <b-col
        lg="8"
        md="6"
      >
        <home-queue-stats :data="dataQueueStats" />
      </b-col>
      <!--/ Queue Stats Card -->
    </b-row>
    <b-row class="match-height">
      <!-- Open Tickets -->
      <b-col
        v-if="dataOpenTickets && $auth.user.email"
        lg="12"
      >
        <home-open-tickets :table-data="dataOpenTickets" />
      </b-col>
      <!--/ Open Tickets -->
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import HomeWelcome from './HomeWelcome.vue'
import HomePhoneStats from './HomePhoneStats.vue'
import HomeOpenTickets from '../../components/_ticket/TicketsCard.vue'
import HomeQueueStats from './HomeQueueStats.vue'
import HomePTO from './HomePTO.vue'

export default {
  components: {
    BRow,
    BCol,
    HomeWelcome,
    HomePhoneStats,
    HomeOpenTickets,
    HomePTO,
    HomeQueueStats,
  },
  data() {
    return {
      dataPBX: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        callsTodayCSTS: 0,
        callsTodaySls: 0,
        callsTodaySvs: 0,
        results: [
          {
            queue: 0,
            weekTotalTime: 0,
            todayTotalTime: 0,
            weekTotalCalls: 0,
            todayTotalCalls: 0,
          },
        ],
      },
      dataOpenTickets: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ticketNo: 0,
            status: '',
            statusColor: '',
            operator: [{ name: '', img: '' }],
            startDate: '',
            updateDate: '',
            summary: '',
            company: '',
            contact: '',
            queue: '',
          },
        ],
      },
      dataQueueStats: {
        queryTime: {
          value: '2021-01-01T00:00:00.000+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            boardName: '',
            countOpen: 0.0,
            countPending: 0.0,
            countStale: 0.0,
            countClosed: 0.0,
            countClosedToday: 0.0,
            openTickets: {},
          },
        ],
      },
      dataTimeOff: {
        queryTime: {
          value: '2021-01-01T00:00:00.00+00:00',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            Values: [''],
            Count: 0,
            Group: [
              {
                firstname: '',
                lastname: '',
                fullname: '',
                ptoDate: '',
                hours: '',
                type: '',
                status: '',
              },
            ],
            Name: '',
          },
        ],
      },
      timer: 0,
      ticketCount: 0,
    }
  },
  created() {
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getData()
    }, 300000)
    this.getData()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
  methods: {
    getData() {
      this.emailAddress = this.$auth.user.email
      this.$http
        .get(
          'https://asi-api2.azure-api.net/phone/queue/stats?key=f56257100ff6407d8572abbe599a6271',
        )
        .then(response => {
          this.dataPBX = response.data
          
        })
      this.$http
        .get(
          'https://asi-api2.azure-api.net/hr/time/off?days=5&key=379da4d9018f4d0096e7195ebc853c39',
        )
        .then(response => {
          this.dataTimeOff = response.data
        })
      this.$http
        .get(
          'https://asi-api2.azure-api.net/ticket/service/boardStats?key=836715d7f31643618225af5af3141717',
        )
        .then(response => {
          this.dataQueueStats = response.data
        })
      if (!this.$auth.user.email) { setTimeout(() => this.getTickets(), 5500) } else this.getTickets()
    },
    getTickets() {
      const email = this.$auth.user.email.replace('aventissystems.com', 'cortavo.com')
      this.$http
        .get(
          // `https://asi-api2.azure-api.net/ticket/member/open?member=${this.$auth.user.email}&key=836715d7f31643618225af5af3141717`,
          // `https://asi-api2.azure-api.net/psa/query/ticket?by=legacyMemberName&value=${this.$auth.user.email}&status=open&key=836715d7f31643618225af5af3141717`,
          `https://asi-api2.azure-api.net/psa/query/ticket?by=MemberEmail&value=${email}&status=open&key=836715d7f31643618225af5af3141717`,
        )
        .then(response => {
          this.dataOpenTickets = response.data
          this.ticketCount = response.data.queryCount
        })
        .catch(error => {
          console.log(error)
          this.dataOpenTickets = null
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
</style>
